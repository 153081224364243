<template>
    <div class="home">

      
        <b-container style="margin-top:4rem; margin-bottom:35px;" class="text-center">

            <b-spinner v-if="isLoading" label="Loading..." style="margin-left: auto; margin-right: auto;" />

            <h2>Available Stores:</h2>
            <div v-for="store in stores" :key="store.id">
              <b-card bg-variant="light">
                        <b-row>
                            <b-col cols="auto" class="mr-auto">
                                <b style="name" v-html="store.name" />
                            </b-col>
                            <b-col cols="auto">
                                <b-button @click="toStore(store.id)" variant="primary" style="background-color: rgb(0, 56, 101);"> Enter Store </b-button>
                            </b-col>
                        </b-row>
                        <!-- <b-row>
                          <b-col>
                            <p class="body" v-html="store.description" />
                          </b-col>
                        </b-row> -->
              </b-card>
            </div>
        </b-container>

    <footer>
      <div class="mt-5">
        <hr/>
        <div class="fluid-container footer justify-content-center">
          <p class="text-center" style="font-size:.85em;">
            <span  v-if="(authenticated && !hasRole('mdh-staff') && !hasRole('mdh-admin') && !hasRole('ie-staff')) || !authenticated">
              For questions, please call the MN Department of Health Help Desk at 651-201-5555 -
            </span>
            <span>v{{appVersion}}</span>
          </p>
        </div>
      </div>
    </footer>
  </div>

</template>

<script>
import axios from 'axios'
import {version} from '/package.json';

export default {
  components: {
  },
  data: () => ({
    isLoading: true,
    stores: {},
  }),
  methods: {
    toStore(id) {
            this.$router.push({ name: 'products', 
                params: { 
                  storeId: id 
                } 
            })
        },
    hasRole(roles) {
        if (this.$keycloak) {
          return this.$keycloak.hasResourceRole(roles)
        } else {
          return false;
        }
      }
    
  },
  computed: {
    userRole() {
      return this.$store.state.userRole
    },
    greeting() {
      return this.$keycloak.fullName
    },
    authenticated: function() {
        if (this.$keycloak) {
          return this.$keycloak.authenticated;
        } else {
          return false;
        }
      },
    appVersion: function() {
      return version;
    }
  },

  mounted() {
    if (this.$keycloak) {
      axios.get(`${process.env.VUE_APP_API_BASE_URL}/stores`, {ignoreHeader:true}).then((response) => {
        this.stores = response.data
        this.isLoading = false;
      
        }
      )
    }
    
  },

  created() {
    document.title = "Store Portal";
  }
}
</script>

<style>

  .title{
    text-align: center;
    color: black;
    font-weight: bold
    }
    .body{
      text-align: center;
      font-weight: 400;
      padding-top: 50px;
      font-style: Arial;
      color: black;
    }
 .green-header {
     color: #60A609;
 }
 .badge-success {
    color: #fff;
    background-color: #175E28;
}

</style>