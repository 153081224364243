<template>
  <div class="quantity">
    <button
      @click="
        changeQuantity(product, -1 + product.qty );
        update();
      "
      class="left-quantity-btn"
      v-bind:style="{
        height:+ qHeight + 'rem',
        width: + qWidth + 'rem'
        }"
      :aria-label="'Remove one ' + product.name" 
    >
      <b>-</b>
    </button>
    <div>
      <input :aria-label="'enter quantity of' + product.name"  class="quantity-input" v-bind:style="{
        height:+ qHeight + 'rem',
        width: + qWidth + 'rem'
        }" type="number" :value="product.qty" @change="changeQuantity(product, $event.target.valueAsNumber)" /> 
    </div>
    <button
      @click="
        changeQuantity(product, 1  + product.qty );
        update();
      "
      class="right-quantity-btn"
      v-bind:style="{
        height:+ qHeight + 'rem',
        width: + qWidth + 'rem'
        }"
      :aria-label="'Add one ' + product.name" 
    >
      <b>+</b>
    </button>
  </div>
</template>

<script>
import {eventBus} from "@/main";

export default {
  name: "QuantityButton",
  props: {
    product: Object,
    qHeight: {
      type: Number,
      default: 2
    },
    qWidth:{
      type: Number,
            default: 2

    }
  },
  methods: {
    changeQuantity(product, amount) {
      this.$emit("change-quantity", product, amount);
    },
    update() {
        this.$forceUpdate();
    },
  },
  created() {      
    eventBus.$on('refresh-quantityButton',  (payload) => {
      if (payload.id === this.product.id) {
          this.update();
      }
    })
  },
  
  
}
</script>

<style scoped>
.quantity {
  display: flex;
}

.left-quantity-btn{

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #eeeeee;
  border: none;
  border-color: rgb(179, 179, 179);
  border-top-left-radius: 3px;
  border-bottom-left-radius:3px;
  font-size: 20px;
  margin-right: 0px

}
.right-quantity-btn {

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #eeeeee;
  border: none;
  border-color: rgb(0, 0, 0);
  border-top-right-radius: 3px;
  border-bottom-right-radius:3px;  margin-left: 0px;
  font-size: 15px;

} 
.quantity-input {
  text-align: center;
  border: none;
  border-color: rgb(179, 179, 179);
  border-radius: 3px;
  background: #ffffff;
} 
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
</style>
