<template>
  <div class="home">
    
    <header>
      <div>
        
        <b-navbar toggleable="lg" ref="subnav" class="subnav">
          <div v-if="catalog.store" style="color: rgb(0, 56, 101)">
            <h1 v-html="catalog.store.name" style="font-size: 24px;"/>
          </div>
          <img v-if="storeId === 'nbs'"
               :src="require('@/assets/3icons - no circles merged.2021-01.png')"
               style=" width: 200px; height: 80px; margin-left: 10px;"
               alt="Three Circle NBS Icon"
          />
            <b-navbar-nav class="subnav-item">
              <b-nav-item
                
                v-if="page === 'Main' && cart.length"
                @click="toCheckOutPage"
              >
                <span style="color: rgb(0, 56, 101)"> Checkout </span>
              </b-nav-item>
              <b-nav-item v-else-if="page === 'Checkout'" @click="toMainPage">
                <span style="color: rgb(0, 56, 101)"> Back </span>
              </b-nav-item>

              <b-nav-item
                class="text-center"
                v-if="page !== 'OrderComplete'"
                right
                @click.stop="showCart = !showCart"
              >
                  <b-icon
                    aria-label="Cart"
                    icon="cart-fill"
                    scale="1.5"
                    style="color: rgb(0, 56, 101)"
                  ></b-icon>
                  <b-badge variant="primary" style="margin-left: 0.5rem" pill>
                    {{ cart.length }}
                  </b-badge>
              </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
      
      </div>
    </header>
    <div
      v-if="page === 'Main'"
      style="margin-top: 3rem; margin-bottom: 35px"
    >
    <!-- style="margin-top: 3rem; margin-bottom: 35px" -->
      <div class="main-page">


        <b-spinner v-if="contentLoading" label="Loading..." style="margin-left: auto; margin-right: auto;" />

        <!-- Category List -->
        <b-container v-if="page" class="sidebar">
          <h2 class="sidebar-title">Categories</h2>
          <img v-if="storeId === 'nbs'"
               :src="require('@/assets/3icons - no circles merged.2021-01.png')"
               style="  margin-left: auto; margin-right: auto; padding-top: 0px; padding-bottom: 5px; width: 80%; display: block"
               alt="Three Circle NBS Icon"
          />
          <a class="sr-only sr-only-focusable" href="#product-display">skip to product display</a>
          
          <ul v-for="category in catalog.categories" :key="category.id">
            <li>
              <b-link class="item"  @click="filterItems(category)" >{{ category.name }} </b-link>
            </li>
          </ul>
          <ul>
            <li>
              <b-link class="item" @click="categoryListing.categories = catalog.categories">All</b-link>
            </li>
          </ul>
        </b-container>
        <!-- Store Description -->
        <a class="sr-only sr-only-focusable" href="product-display">skip to product display</a>
        <img v-if="storeId === 'nbs'"
             :src="require('@/assets/mdh-68.jpg')"
             style="width: 250px; float: right; margin-right: 20px; grid-area: sideimage"
             alt="Nurse with NBS printout">
        <b-container  v-if="catalog.store" class="description" style="margin-bottom: 3rem;">
          <p style="clear: right" class="text-center" v-html="catalog.store.description" />
        </b-container>
        <!-- Cart  -->
          <div 
            v-if="showCart"
            ref="cart" 
            class="cart bg-light mx-auto"
            :style ="{
              position: isMobile ? 'relative' : isAtBottom ? 'absolute' : 'fixed', 
              gridArea:  isMobile ? 'cart' : 'none',
              alignSelf: isMobile ? 'center' : 'auto',
              zIndex: isMobile ? '0' : '1',
              marginTop: isMobile ? '-50px' : isAtBottom ? '-50px' : '0px', 
              top: isMobile ? '0' : isAtTop ? '0' : 'auto'}"
            >
            <div
              class="col-12 p-3 border-bottom"
              style="font-weight: 700; font-size: 18px; overflow: hidden;"
            >
              Your Cart 

              <b-icon-x class="x" @click="showCart = false">
              </b-icon-x>

              </div>

            <div class="col-12 px-1 mt-3" style="max-height: 350px; overflow: auto">
              <div v-if="cart.length">
                <div
                  v-for="product in cart"
                  :key="product.id"
                  class="d-flex justify-content-between my-4 border-bottom"
                >
                  <div class="col-3 px-1 text-center">
                    <img
                      v-if="product.imageUrl == null"
                      :src="require('@/assets/placeholder.jpg')"
                      alt=""
                      width="70%"
                      height="50px"
                      style="object-fit: cover; border-radius: 10px;"
                    />
                    <img
                      v-else
                      :src="product.imageUrl"
                      alt=""
                      width="70%"
                      height="50px"
                      style="object-fit: cover; border-radius: 10px;"
                    />
                  </div>
                  <div class="col-4 px-1 d-flex">
                    
                    <div style="font-size: 13.5px; font-weight: bold">
                      <p v-html="product.name" />
                    </div>
                  </div>
                  <div class="px-1 d-flex">
                    <QuantityButton  
                      class="quantity"
                      @change-quantity="changeQuantity"
                      :product="product"
                    />
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="justify-content-between col-12 text-center px-1 mt-3"
                  style="min-height: 50px"
                >
                  <p class="text-muted" style="font-weight: 700">Your cart is empty</p>
                </div>
              </div>
            </div>
            <div class="col-12 my-2" style="position:absolute; bottom: 0px;">
              <b-button
                v-if="cart.length"
                class="p-3 text-white border-0 w-100"
                style="
                  background-color: rgb(0, 56, 101);
                  font-weight: 700;
                  font-size: 14px;
                  border-radius: 10px;
                "
                @click="toCheckOutPage"
                >Checkout</b-button
              >
            </div>
          </div>

        
        <!-- Product Display -->
        <b-container class="category mx-auto" id="product-display">
          <Category
            @rem-cart="removeFromCart"
            @add-cart="addToCart"
            v-for="category in categoryListing.categories"
            :key="category.id"
            :category="category"
            :store-id="storeId"
          />
          
        </b-container>
      </div>
    </div>
    <b-container
      v-if="page == 'Checkout'"
      style="margin-top: 4rem; margin-bottom: 35px"
    >
      <b-card bg-variant="light">
        <b-form-group
          label="Shopping Cart"
          label-size="lg"
          label-class="front-weight-bold pt-0"
        />

        <b-table class="table" show-empty :fields="fields" :items="cart">

          <template v-slot:cell(name)="data">
            <div style="width: 115%"> 
              <span v-html="data.item.name" />

              <b-icon v-if="data.item.description" icon="info-circle-fill" :aria-label="data.item.name + ' Infomation '" 
              scale="1" style="margin-left:5px;"  
              v-b-toggle="'collapse-'+data.item.id"
               ></b-icon> 
                  <b-collapse :id="'collapse-'+data.item.id" class="mt-2">
                    <p v-html="data.item.description"></p>
                  </b-collapse>
              </div>

          </template>

          <template v-slot:cell(imageUrl)="data">
            <b-img
              v-if="data.item.imageUrl == null"
              :src="require('@/assets/placeholder.jpg')"
              alt=""
              width="100px"
              height="100px"
              style="float: none"
            />
            <b-img
              v-else
              :src="data.value"
              alt=""
              width="100px"
              height="100px"
              style="float: left"
            />
          </template>
          <template v-slot:cell(qty)="data">
            <QuantityButton  
              @change-quantity="changeQuantity"
              :product="data.item"
              :qHeight="2.6"
              :qWidth="2.6"
            />
          </template>
          <template v-slot:cell(id)="data">
            <b-icon
              icon="x-lg"
              :aria-label="'Remove ' + data.item.name"
              scale="0.8"
              class="table-x"
              @click.stop="removeFromCart(data.value)"
            ></b-icon>
          </template>
        </b-table>
      </b-card>

      <b-container>
        <b-form @submit.prevent="submitOrder">
          <b-card bg-variant="light">
            <b-form-group
              label-cols-lg="3"
              label="Customer Information"
              label-size="lg"
              label-class="front-weight-bold pt-0"
            >
            <b-form-group label-cols="3" style="margin-bottom: 2rem">
                        <p v-html="catalog.store.customerInformationDescription"></p>
            
            </b-form-group>
              
              <b-form-group
                label="First Name"
                label-cols="3"
                label-for="input-firstName"
                label-align-sm="right"
              >
                <b-form-input
                  id="input-firstName"
                  v-model="$v.data.customer.firstName.$model"
                  :state="$v.data.customer.firstName.$dirty ? !$v.data.customer.firstName.$error : null"
                ></b-form-input>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>

              <b-form-group
                label="Last Name"
                label-for="input-lastName"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-input
                  id="input-lastName"
                  v-model="$v.data.customer.lastName.$model"
                  :state="$v.data.customer.lastName.$dirty ? !$v.data.customer.lastName.$error : null"
                ></b-form-input>

                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>

              <b-form-group  v-if="catalog.store.name === '<p>MDH PHL Orders Store (Infectious Disease Lab)</p>' ||
                                    storeId === 'arnl'"
                label="Facility Name"
                label-for="input-companyName"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-input
                  id="input-companyName"
                  v-model="$v.data.customer.companyName.$model"
                  :state="$v.data.customer.companyName.$dirty ? !$v.data.customer.companyName.$error : null"
                ></b-form-input>

                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>

              <b-form-group  v-else
                             label="Company Name"
                             label-for="input-companyName"
                             label-cols="3"
                             label-align-sm="right"
              >
                  <b-form-input
                          id="input-companyName"
                          v-model="$v.data.customer.companyName.$model"
                          :state="$v.data.customer.companyName.$dirty ? !$v.data.customer.companyName.$error : null"
                  ></b-form-input>

                  <small tabindex="-1" class="form-text" style="color:#6B747B;">Optional</small>
              </b-form-group>

              <b-form-group
                label="Phone Number"
                label-for="input-telephone"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-input
                  id="input-telephone"
                  type="tel"
                  maxlength="17"
                  placeholder="+1 (xxx) xxx-xxxx"
                  v-model="$v.data.customer.telephone.$model"
                  :state="$v.data.customer.telephone.$dirty ? !$v.data.customer.telephone.$error : null"
                  aria-describedby="input-feedback-telephone"
                  v-mask="'+1 (###) ###-#####'"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-telephone">
                  Invalid phone number
                </b-form-invalid-feedback>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>
              <b-form-group
                  v-if="storeId === 'arnl'"
                  label="Secure Fax Number"
                  label-for="input-fax"
                  label-cols="3"
                  label-align-sm="right"
              >
                <b-form-input
                    id="input-fax"
                    type="tel"
                    maxlength="17"
                    placeholder="+1 (xxx) xxx-xxxx"
                    v-model="$v.data.customer.fax.$model"
                    :state="$v.data.customer.fax.$dirty ? !$v.data.customer.fax.$error : null"
                    aria-describedby="input-feedback-fax"
                    v-mask="'+1 (###) ###-#####'"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-fax">
                  Invalid fax number
                </b-form-invalid-feedback>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>
              <b-form-group
                label="Email Address"
                label-for="input-emailAddress"
                label-cols="3"
                label-align-sm="right"
              >

                <b-form-input
                  id="input-emailAddress"
                  v-model="$v.tempEmail1.$model"
                  :state="$v.tempEmail1.$dirty ? !$v.tempEmail1.$error : null"
                  type="text"
                  maxlength="100"
                  aria-describedby="input-feedback-email"
                ></b-form-input>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
                <b-form-input
                  v-if="numEmails >= 2"
                  id="input-emailAddress"
                  v-model="$v.tempEmail2.$model"
                  :state="$v.tempEmail2.$dirty ? !$v.tempEmail2.$error : null"
                  type="text"
                  maxlength="100"
                  aria-describedby="input-feedback-email"
                  style="margin-top: 5px"
                ></b-form-input>
                <b-form-input
                    v-if="numEmails >= 3"
                    id="input-emailAddress"
                    v-model="$v.tempEmail3.$model"
                    :state="$v.tempEmail3.$dirty ? !$v.tempEmail3.$error : null"
                    type="text"
                    maxlength="100"
                    aria-describedby="input-feedback-email"
                    style="margin-top: 5px"
                ></b-form-input>
                <b-form-invalid-feedback id="input-feedback-email">
                  Invalid email address
                </b-form-invalid-feedback>

                <b-button v-if="numEmails < 3" @click="numEmails++" style="margin-top: 5px">Add Email (limit 3)</b-button>
                <b-button v-if="numEmails > 1" @click="removeEmail" style="margin-top: 5px">Remove Email</b-button>
              </b-form-group>
            </b-form-group>
          </b-card>


          <b-card bg-variant="light">
            <b-form-group
              label-cols-lg="3"
              label="Shipping Information"
              label-size="lg"
              label-class="front-weight-bold pt-0"
              class="mb=0"
            >
              <b-form-group label-cols="3" style="margin-bottom: 2rem">

                <p v-html="catalog.store.shippingInformationDescription">
                </p>
              </b-form-group>

              <b-form-group
                v-if="catalog.store.enablePickup === 'Y'"
                label="Delivery Method"
                label-for="input-method"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-select
                  v-if="catalog.store.enablePickup==='Y'"
                  id="input-method"
                  v-model="$v.data.delivery.method.$model"
                  :state="$v.data.delivery.method.$dirty ? !$v.data.delivery.method.$error : null"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled>--Please select a delivery method--</b-form-select-option>
                  </template>
                  <b-form-select-option value="SHIP">SHIP</b-form-select-option>
                  <b-form-select-option v-if="catalog.store.enablePickup === 'Y'" value="PICKUP">PICKUP</b-form-select-option>
                  <b-form-select-option v-if="catalog.store.name === '<p>MDH PHL Orders Store (Infectious Disease Lab)</p>'" value="COURIER">COURIER</b-form-select-option>
                </b-form-select>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>

<!--                COURIER DROPDOWN -->
              <b-form-group
                v-if="data.delivery.method == 'PICKUP'"
                label="Pick Up Date"
                label-for="input-arrivalDate"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-datepicker
                  v-if="catalog.store.name === '<p>MDH PHL Orders Store (Infectious Disease Lab)</p>'"
                    id="input-arrivalDate"
                    v-model="$v.data.delivery.arrivalDate.$model"
                    :state="$v.data.delivery.arrivalDate.$dirty ? !$v.data.delivery.arrivalDate.$error : null"
                    :date-disabled-fn="dateDisabled"
                    locale="en"
                    :min="packshipMinDate"
                    :max="maxDate"
                ></b-form-datepicker>
                <b-form-datepicker
                  v-else
                    id="input-arrivalDate"
                    v-model="$v.data.delivery.arrivalDate.$model"
                    :state="$v.data.delivery.arrivalDate.$dirty ? !$v.data.delivery.arrivalDate.$error : null"
                    :date-disabled-fn="dateDisabled"
                    locale="en"
                    :min="minDate"
                    :max="maxDate"
                ></b-form-datepicker>
<!--                  todo: also make sure this one is -4-->
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>

              <b-form-group
                v-if="data.delivery.method == 'PICKUP'"
                label="Pick Up Time"
                label-for="input-arrivalTime"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-timepicker
                  id="input-arrivalTime"
                  reset-button
                  v-model="$v.data.delivery.arrivalTime.$model"
                  :state="$v.data.delivery.arrivalTime.$dirty ? !$v.data.delivery.arrivalTime.$error : null"
                  aria-describedby="input-feedback-arrivalTime"
                ></b-form-timepicker>
                <b-form-invalid-feedback id="input-feedback-arrivalTime">
                  Invalid Time. Please pick a time from 8:00 am to 4:30 pm
                </b-form-invalid-feedback>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>
<!--                SHIP DROPDOWN -->
              <b-form-group
                v-if="data.delivery.method == 'SHIP' || catalog.store.enablePickup === 'N'"
                label="Street Address 1"
                label-for="input-streetAddress1"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-input
                  id="input-streetAddress1"
                  v-model="$v.data.delivery.streetAddress1.$model"
                  :state="$v.data.delivery.streetAddress1.$dirty ? !$v.data.delivery.streetAddress1.$error : null"
                  type="text"
                  maxlength="25"
                ></b-form-input>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>

              <b-form-group
                v-if="data.delivery.method == 'SHIP' || catalog.store.enablePickup === 'N'"
                label="Street Address 2"
                label-for="input-streetAddress2"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-input
                  id="input-streetAddress2"
                  v-model="$v.data.delivery.streetAddress2.$model"
                  :state="$v.data.delivery.streetAddress2.$dirty ? !$v.data.delivery.streetAddress2.$error : null"
                  type="text"
                  maxlength="25"
                ></b-form-input>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Optional</small>
              </b-form-group>

              <b-form-group
                v-if="data.delivery.method == 'SHIP' || catalog.store.enablePickup === 'N'"
                label="City"
                label-for="input-city"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-input
                  id="input-city"
                  v-model="$v.data.delivery.city.$model"
                  :state="$v.data.delivery.city.$dirty ? !$v.data.delivery.city.$error : null"
                  maxlength="25"
                ></b-form-input>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>
              <b-form-group
                v-if="data.delivery.method == 'SHIP' || catalog.store.enablePickup === 'N'"
                label="State"
                label-for="input-state"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-select
                  id="input-state"
                  v-model="$v.data.delivery.state.$model"
                  :state="$v.data.delivery.state.$dirty ? !$v.data.delivery.state.$error : null"
                >
                  <template v-if="storeId === 'nbs'" #default>
                    <b-form-select-option :value="opts.stateOptions[23].text">
                      {{ opts.stateOptions[23].text }}
                    </b-form-select-option>
                  </template>
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- Please select a state or territory--
                      </b-form-select-option>
                  </template>
                  <b-form-select-option
                    v-for="state in opts.stateOptions"
                    :key="state"
                    :value="state.text"
                  >
                    {{ state.text }}
                  </b-form-select-option>
                </b-form-select>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>

              <b-form-group
                v-if="data.delivery.method == 'SHIP' || catalog.store.enablePickup === 'N'"
                label="Zip Code"
                label-for="input-postCode"
                label-cols="3"
                label-align-sm="right"
              >
                <b-form-input
                  id="input-postCode"
                  v-model="$v.data.delivery.postalCode.$model"
                  :state="$v.data.delivery.postalCode.$dirty ? !$v.data.delivery.postalCode.$error : null"
                  maxlength="10"
                ></b-form-input>
                <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>
              <b-form-group
                      v-if="(data.delivery.method == 'SHIP' || catalog.store.enablePickup === 'N') && (catalog.store.name === '<p>MDH PHL Orders Store (Infectious Disease Lab)</p>')"
                      label="Need-by Date"
                      label-for="input-arrivalDate"
                      label-cols="3"
                      label-align-sm="right"
              >
                  <b-form-datepicker
                          v-if="catalog.store.name === '<p>MDH PHL Orders Store (Infectious Disease Lab)</p>'"
                            id="input-arrivalDate"
                            v-model="$v.data.delivery.arrivalDate.$model"
                            :state="$v.data.delivery.arrivalDate.$dirty ? !$v.data.delivery.arrivalDate.$error : null"
                            :date-disabled-fn="dateDisabled"
                            locale="en"
                            :min="packshipMinDate"
                            :max="maxDate"
                  ></b-form-datepicker>
                  <b-form-datepicker
                          v-else
                            id="input-arrivalDate"
                            v-model="$v.data.delivery.arrivalDate.$model"
                            :state="$v.data.delivery.arrivalDate.$dirty ? !$v.data.delivery.arrivalDate.$error : null"
                            :date-disabled-fn="dateDisabled"
                            locale="en"
                            :min="minDate"
                            :max="maxDate"
                  ></b-form-datepicker>
                  <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
              </b-form-group>
<!--                COURIER DROPDOWN -->
                <b-form-group
                        v-if="data.delivery.method == 'COURIER' || catalog.store.enablePickup === 'N'"
                        label="Street Address 1"
                        label-for="input-streetAddress1"
                        label-cols="3"
                        label-align-sm="right"
                >
                    <b-form-input
                            id="input-streetAddress1"
                            v-model="$v.data.delivery.streetAddress1.$model"
                            :state="$v.data.delivery.streetAddress1.$dirty ? !$v.data.delivery.streetAddress1.$error : null"
                            type="text"
                            maxlength="25"
                    ></b-form-input>
                    <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
                </b-form-group>
                <b-form-group
                        v-if="data.delivery.method == 'COURIER' || catalog.store.enablePickup === 'N'"
                        label="City"
                        label-for="input-city"
                        label-cols="3"
                        label-align-sm="right"
                >
                    <b-form-input
                            id="input-city"
                            v-model="$v.data.delivery.city.$model"
                            :state="$v.data.delivery.city.$dirty ? !$v.data.delivery.city.$error : null"
                            maxlength="25"
                    ></b-form-input>
                    <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
                </b-form-group>
                <b-form-group
                        v-if="data.delivery.method == 'COURIER' || catalog.store.enablePickup === 'N'"
                        label="State"
                        label-for="input-state"
                        label-cols="3"
                        label-align-sm="right"
                >
                    <b-form-select
                            id="input-state"
                            v-model="$v.data.delivery.state.$model"
                            :state="$v.data.delivery.state.$dirty ? !$v.data.delivery.state.$error : null"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled
                            >-- Please select a state or territory--
                            </b-form-select-option>
                        </template>
                        <b-form-select-option
                                v-for="state in opts.stateOptions"
                                :key="state"
                                :value="state.text"
                        >
                            {{ state.text }}
                        </b-form-select-option>
                    </b-form-select>
                    <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
                </b-form-group>

                <b-form-group
                        v-if="data.delivery.method == 'COURIER' || catalog.store.enablePickup === 'N'"
                        label="Zip Code"
                        label-for="input-postCode"
                        label-cols="3"
                        label-align-sm="right"
                >
                    <b-form-input
                            id="input-postCode"
                            v-model="$v.data.delivery.postalCode.$model"
                            :state="$v.data.delivery.postalCode.$dirty ? !$v.data.delivery.postalCode.$error : null"
                            maxlength="10"
                    ></b-form-input>
                    <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
                </b-form-group>
                <b-form-group
                        v-if="(data.delivery.method == 'COURIER' || catalog.store.enablePickup === 'N') && catalog.store.name === '<p>MDH PHL Orders Store (Infectious Disease Lab)</p>'"
                        label="Pick Up Date"
                        label-for="input-arrivalDate"
                        label-cols="3"
                        label-align-sm="right"
                >
                    <b-form-datepicker
                            id="input-arrivalDate"
                            v-model="$v.data.delivery.arrivalDate.$model"
                            :state="$v.data.delivery.arrivalDate.$dirty ? !$v.data.delivery.arrivalDate.$error : null"
                            :date-disabled-fn="dateDisabled"
                            locale="en"
                            :min="packshipMinDate"
                            :max="maxDate"
                    ></b-form-datepicker>
<!--                    todo: make sure minDate -4 is tomorrow ADDING THINGS TO minDate here RUINS IT-->
                    <small tabindex="-1" class="form-text" style="color:#6B747B;">Required</small>
                </b-form-group>



            </b-form-group>
          </b-card>

          <b-card bg-variant="light">
            <b-form-group
              label="Additional Comments"
              label-size="lg"
              label-class="front-weight-bold pt-0"
              class="mb-1"
            >
              <b-form-group
                label="Customer Comments"
                label-for="input-comments"
                label-class="sr-only"
                >
                <b-form-textarea
                  id="input-comments"
                  v-model="data.comments"
                  placeholder="Comments"
                  rows="7"
                  maxlength="250"
                ></b-form-textarea>
              </b-form-group>
            </b-form-group>
          </b-card>
          <b-button v-if="cart.length" variant="primary" type="submit">
            Submit Order
          </b-button>
        </b-form>
      </b-container>
    </b-container>

    <b-container
      style="margin-top: 4rem; margin-bottom: 35px"
      v-if="page == 'OrderComplete'"
      class="text-center mx-auto"
    >
      <b-spinner class="" v-if="!orderId" label="Loading..." style="margin-left: auto; margin-right: auto;" />
      <div v-else>
        <p tabindex="0" style="text-align: center">Your order has been confirmed</p>
        <p tabindex="0" style="text-align: center"> Order Code: {{orderId}} </p>
      </div>
    </b-container>

    <footer v-if="!contentLoading">
      <div class="mt-5">
        <hr/>
        <div class="fluid-container footer justify-content-center">
          <p class="text-center" style="font-size:.85em;">
            <span>
              {{catalog.store.footer}} -
            </span>
            <span>v{{appVersion}}</span>
            
            <br>
            <button style="background:#e9e9e9; border-radius: 0.2rem; display: inline-block; font-size:.85em" @click="scrolltoTop">Scroll to Top</button>
          </p>
        </div>
      </div>
    </footer>    

  </div>
</template>


<script>
import axios from "axios";
import axiosRetry from "axios-retry";
import Category from "@/components/Category.vue";
import { eventBus } from "@/main";
import { required, email, minLength, maxLength, numeric, requiredUnless, requiredIf } from 'vuelidate/lib/validators'
import timeBetween from  '@/validators/timeBetween.js'
import {commonOptions} from '../mixins/commonOptions.js'

import QuantityButton from "@/components/QuantityButton.vue";
import {version} from '/package.json';
import {mask} from 'vue-the-mask';


axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

export default {
  components: {
    Category,
    QuantityButton,
  },
  directives: {mask},
  mixins: [commonOptions],
  data() {

    return {

      contentLoading: true,
      showCart: false, 
      enablePickup: false,

      page: "Main",

      cart: [],
      isAtBottom: true,
      isAtTop: false, 

      orderId: null,

      numEmails: 1,
      tempEmail1: null,
      tempEmail2: null,
      tempEmail3: null,

      fields: [
        { key: "imageUrl", label: "Product Picture", thClass: "sr-only", tdClass: "align-middle text-center" },
        { key: "name", label: "Product Name", tdClass: "align-middle" },
        { key: "qty", label: "Quantity", tdClass: "align-middle"},
        { key: "id", label: "Cancel Option", thClass: "sr-only", tdClass: "align-middle text-center" },
      ],

      catalog: {},
      categoryListing: {},
      data: {
        storeId: null,
        items: [],
        customer: {
          firstName: null,
          lastName: null,
          companyName: null,
          telephone: null,
          fax: null,
          emailAddress: null,
        },
        comments: "",
        delivery: {
          method: null,
          arrivalDate: "",
          arrivalTime: null,
          streetAddress1: "",
          streetAddress2: "",
          state: null,
          city: "",
          postalCode: "",
        },
      },
    };
  },
  created() {
    document.title = "Welcome - PHL Order Portal";
  },
  methods: {
    login() {
      window.location.replace("/Home");
    },
    toMainPage() {
      this.page = "Main";
    },
    toCheckOutPage() {
      this.page = "Checkout";
      this.showCart = false;
    },
    // handle scroll for cart of side of page 
    handleScroll() {
      if (this.$refs.cart) {

        const subnav = this.$refs.subnav
        
        if ( subnav.$el.getBoundingClientRect().bottom < 0) {
          this.isAtBottom = false
          this.isAtTop = true
        } else {
          this.isAtBottom = true
          this.isAtTop = false
        }
      }
    },
    scrolltoTop() {
      window.scrollTo({top:0, left:0, behavior: 'smooth'});
    },
    removeFromCart(productId) {
      // find object with the product id
      const product = this.cart.find((element) => element.id == productId);
      // if null then item not in cart
      if (product != null) {
        product.qty = 0;
        this.cart = this.cart.filter((element) => element !== product);
      }

      // raise an event that will trigger the product component to update
      eventBus.$emit("refresh-product", { id: productId });
    },
    addToCart(product) {
      if (!this.cart.includes(product)) {
        product.qty = 1;
        this.cart = [...this.cart, product];
      }
      if(product.sendNotification === "Y"){
        let namefield = product.name.replace(/<(.|\n)*?>/g, '');
        this.showRootToast(namefield,  product.notification, 'success')
      }
    },
    showRootToast(title, message, type) {
        this.$root.$bvToast.toast(message, {
            title: title,
            toaster: 'b-toaster-top-center',
            solid: true,
            variant: type,
            appendToast: true
        })
    },
    submitOrder() {
      this.$v.$touch();
      if (this.$v.$invalid) {
          console.log("InValid");
          return
      }
      this.combineEmail()
      console.log("Valid");
      this.data.storeId = this.$route.params.storeId
        //console.log(this.data)
        //console.log(this.data.storeId)
      this.page = "OrderComplete";

      this.cart.map((product) =>
        this.data.items.push({
          productId: product.id,
          qty: product.qty,
          categoryId: product.categoryId,
        })
      );
      axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/cart`, this.data, {
          ignoreHeader: true,
        })
        .then((response) => {
          //console.log(response);

          // clear the shopping car
          this.cart = [];

          // display a thank you message/confirmation
          this.orderId = response.data
          
        });
    },
    filterItems(category) {
      this.categoryListing.categories = this.catalog.categories.filter(
        (item) => item == category
      );
    },
    changeQuantity(product, amount) {
      // if maxQuantity is not set, use default cap of 10000
      if (product.maxQuantity == null && amount > 10000) {
        product.qty = 10000;
      }
      // if maxQuantity is set 
      else if (product.maxQuantity > 0 && amount > product.maxQuantity) {
        product.qty = product.maxQuantity
      }
      else {
        product.qty = amount;
        if (product.qty <= 0) {
          this.removeFromCart(product.id);
        }
      }

      // raise an event that will trigger the quantityButton component to update
      eventBus.$emit("refresh-quantityButton", { id: product.id });
    },

    removeEmail(){
      switch (this.numEmails){
        case 2:
          this.tempEmail2 = null;
          this.$v.tempEmail2.$reset();
          break;
        case 3:
          this.tempEmail3 = null;
          this.$v.tempEmail3.$reset();
          break;
      }
      this.numEmails--;
    },

    combineEmail(){
      let combined = this.tempEmail1;
      if(this.tempEmail2 != null){
        combined += ", " + this.tempEmail2;
      }
      if(this.tempEmail3 != null){
        combined += ", " + this.tempEmail3;
      }
      this.data.customer.emailAddress = combined;
    },
    // Disable weekends (Sunday = `0`, Saturday = `6`)
    dateDisabled(ymd, date) {
        return date.getDay() === 0 || date.getDay() === 6
      }
  },
  mounted() {

    // If the device is a mobile device, disable the cart scrolling
    this.isMobile = window.matchMedia('(max-width: 480px)').matches

    if(!this.isMobile) {
      window.addEventListener('scroll', this.handleScroll)
    }
   
    window.addEventListener('scroll', this.handleScroll)

    this.storeId = this.$route.params.storeId

    axios
      .get(`${process.env.VUE_APP_API_BASE_URL}/catalog/${this.storeId}`, {
        ignoreHeader: true,
      })
      .then((response) => {
        var temp = response.data;
        //check if items will be custom sorted 
        const determinant = response.data.categories[0].sortOrder;
        const det2 = response.data.categories[1].sortOrder;
        //sorting function
        response.data.categories.sort(
          function(a,b) {
            let result;
            if (determinant===0 && det2===0){
              // console.log('alpha sorting categories');
              result = (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);
            }else{
              // console.log('custom sorting categories');
              result = (a.sortOrder > b.sortOrder) ? 1 : ((b.sortOrder > a.sortOrder) ? -1 : 0);
            }
            return result;
          }
        );
        this.catalog = { ...temp };
        console.log(this.catalog)
        this.categoryListing = { ...temp };
        this.contentLoading = false;
        console.log(response.data)
      });
  },
  validations() {
    return {
        data: {
          customer: {
            firstName: {
                required,
                maxLength: maxLength(50)
            },
            lastName: {
                required,
                maxLength: maxLength(50)
            },
            companyName: {
                required: requiredIf(function() {
                    return (this.storeId === "packship" || this.storeId === "arnl")
                }),
                maxLength: maxLength(100)
            },
            telephone: {
                required,
                minLength: minLength(17),
            },
            fax: {
              required: requiredIf(function() {
                return this.data.storeId === "arnl"
              }),
              minLength: minLength(17),
            }
          },
          comments: {
              maxLength: maxLength(250)
          },
          delivery: {
            method: {
                required,
            },
            arrivalDate: {
                required: requiredIf(function() {
                    return ((this.data.delivery.method === 'SHIP' && this.storeId === "packship") || this.data.delivery.method === 'COURIER' || this.data.delivery.method === 'PICKUP')
                }),
            },
            arrivalTime: {
                required: requiredIf(function() {
                    return this.data.delivery.method === 'PICKUP'
                }),
                maxLength: maxLength(20),
                timeBetween: timeBetween('08:00:00', '16:30:00')
            },
            streetAddress1: {
                required: requiredIf(function() {
                    return (this.data.delivery.method === 'SHIP' || this.data.delivery.method === 'COURIER')
                }),
                maxLength: maxLength(25)
            },
            streetAddress2: {
                maxLength: maxLength(25)
            },
            state: {
                required: requiredIf(function() {
                    return (this.data.delivery.method === 'SHIP' || this.data.delivery.method === 'COURIER')
                }),
            },
            city: {
                required: requiredIf(function() {
                    return (this.data.delivery.method === 'SHIP' || this.data.delivery.method === 'COURIER')
                }),
                maxLength: maxLength(25)
            },
            postalCode: {
                required: requiredIf(function() {
                    return (this.data.delivery.method === 'SHIP' || this.data.delivery.method === 'COURIER')
                }),
                numeric,
                maxLength: maxLength(10)
            },
        }
      } ,
      tempEmail1:{
        email,
        required,
        maxLength: maxLength(100)
      },
      tempEmail2:{
        email,
        maxLength: maxLength(100)
      },
      tempEmail3:{
        email,
        maxLength: maxLength(100)
      },
    }
  },
  computed: {
    appVersion: function() {
      return version;
    },
    packshipMinDate() {
      const today = new Date();
      const packshipMinDate = new Date();
      packshipMinDate.setDate(today.getDate() + 1);

      return packshipMinDate
    },
    minDate() {
      const today = new Date();
      const minDate = new Date();
      minDate.setDate(today.getDate() + 5);
      
      return minDate
    },
    midDate() {
        const today = new Date();
        const midDate = new Date();
        midDate.setDate(today.getDate() + 3);

        return midDate
    },
    maxDate() {
      const today = new Date();
      const maxDate = new Date();
      maxDate.setMonth(today.getMonth() + 2);
      return maxDate
    },
  },
};
</script>

<style>
li {
  display: list-item !important;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: auto;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  border: 0;
 }


.subnav {
  background-color: #f4f4f4;
  font-weight: bold;
  width: 100%;
  padding: 30px;
}
.subnav-item {
  margin-left: auto;
}


.main-page {
  max-width: 100% !important;
  display: grid;
  gap: 0;
  position: relative;
  grid-template-columns: .2fr 1fr .2fr;
  grid-template-rows: "<auto-track-list>";
  grid-template-areas: "blank blank cart"
                        "sidebar description sideimage"
                        "sidebar category _";
}

.cart {
  width: 350px;
  background-color: #d9ebfc; 
  border-radius: 3px;
  border: 1px solid rgb(201, 201, 201);
  right: 0;
  z-index: 10;
  height: 500px;
  overflow: hidden;
}
.quantity {
  align-items: center;
  margin-right: 1rem;
  width: 6rem;
  height: 2rem;
}

.x{
  font-weight: 700;
  font-size: 25px;
  float: right;
  margin-left: 1px;
  border: none;
  cursor: pointer;
}


.description {
  z-index: 0;
  overflow: hidden;
  grid-area: description;
}
.category {
  z-index: 0;
  overflow: hidden;
  grid-area: category;
  max-width: fit-content;
 
}
.sidebar {
  grid-area: sidebar;
}
.cart-wrapper {
  grid-area: cart-wrapper;
}
.sidebar-title {
  text-align: left;
  font-size: 18px;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  color: rgb(0, 56, 101);
  border-bottom: 3px solid rgb(0, 56, 101);
  font-weight: bold;
}
.sidebar .item {
  text-decoration: none;
  color: black;
  font-size: 14px;
}
.sidebar .item:hover {
  text-decoration: underline;

}


.table-btn {
    width: 7.5rem;
  }

/* Tablet */
@media (max-width: 800px) {
  .table {
  }
  .table img {
      width: 75px;
      height: 75px;
  }
}



/* Mobile */
@media (max-width: 500px) {
  .main-page {
    grid-template-columns: 1fr;
    grid-template-areas:
      "cart"
      "description"
      "sidebar"
      "category";
  }
  .cart {
    margin-top: -50px;
    /* min-width: 300px; */
    position: absolute;
    top: 0px;
    border: none;
    height: 450px;
    right: 2px;
    z-index: 10;
    grid-area: cart;
    overflow: hidden;
    align-self: center;
  }
  
  .subnav-item {
  margin: auto;
  }
  .sidebar-title {
    text-align: center;
    margin: 0.5rem;
  }
  .sidebar .item {
    text-align: center;
  }
  .table {
    font-size: 14px;
  }
  .table img {
    width: 0px;
    height: 0px;
  }
  .table-btn {
    width: 6.5rem;
  }
}
</style>
