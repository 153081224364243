import { helpers } from 'vuelidate/lib/validators'

export default function timeBetween(minTime, maxTime) {
    return helpers.withParams(
        {type: 'timeBetween', minTime, maxTime},
        (value) => {

            if (!value) {
                return !helpers.req(value)
            }
    
            const time = new Date('1970-01-01T' + value + 'Z')
            const min = new Date('1970-01-01T' + minTime + 'Z')
            const max = new Date('1970-01-01T' + maxTime + 'Z')
    
            return !helpers.req(value) || time >= min && time <= max 
        }
    )
}