var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quantity"},[_c('button',{staticClass:"left-quantity-btn",style:({
      height:+ _vm.qHeight + 'rem',
      width: + _vm.qWidth + 'rem'
      }),attrs:{"aria-label":'Remove one ' + _vm.product.name},on:{"click":function($event){_vm.changeQuantity(_vm.product, -1 + _vm.product.qty );
      _vm.update();}}},[_c('b',[_vm._v("-")])]),_c('div',[_c('input',{staticClass:"quantity-input",style:({
      height:+ _vm.qHeight + 'rem',
      width: + _vm.qWidth + 'rem'
      }),attrs:{"aria-label":'enter quantity of' + _vm.product.name,"type":"number"},domProps:{"value":_vm.product.qty},on:{"change":function($event){return _vm.changeQuantity(_vm.product, $event.target.valueAsNumber)}}})]),_c('button',{staticClass:"right-quantity-btn",style:({
      height:+ _vm.qHeight + 'rem',
      width: + _vm.qWidth + 'rem'
      }),attrs:{"aria-label":'Add one ' + _vm.product.name},on:{"click":function($event){_vm.changeQuantity(_vm.product, 1  + _vm.product.qty );
      _vm.update();}}},[_c('b',[_vm._v("+")])])])}
var staticRenderFns = []

export { render, staticRenderFns }