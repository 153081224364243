<template>
  <div>
    <b-card v-if="product.active === 'Y'" >
      <div class="img">
        <img v-if="product.imageUrl == null" :src="require('@/assets/placeholder.jpg')" style="width: 200px; height: 200px; width: 100%; height: 100%"  alt="No Image"/>
        <v-img src v-if="product.imageUrl != null" :high-url="product.imageUrl">
          <img

            :src="product.imageUrl"
            style=" width: 200px; height: 200px;"
            :alt="product.name"
          />
        </v-img>


      </div>
      <b-card-title class="title"><span v-html="product.name" /> <b-icon v-if="product.description" icon="info-circle-fill" :aria-label="product.name + ' Infomation '"  v-b-toggle="'collapse-'+product.id" scale="1" style="margin-left:5px;"></b-icon> 
      </b-card-title>
      <b-collapse :id="'collapse-'+product.id" class="mt-2">
        <p v-html="product.description" />
      </b-collapse>
      <div class="button-group">
        <b-button v-if="product.qty == null || product.qty <= 0" :aria-label="'Add ' + product.name + ' to cart'" class="btn-add" @click.stop="sendData(product); update();" > Add to Cart  <b-icon icon="cart-plus-fill"  aria-label="" alt="Add to cart icon" scale="1" style="margin-left:5px"></b-icon> </b-button>
        <b-button v-else :aria-label="'Remove ' + product.name + ' from cart'"   class="btn-rem" @click.stop="$emit('rem-cart', product.id); update();"> Remove from Cart  <b-icon icon="cart-dash-fill" aria-label="" alt="Remove from cart icon" scale="1" style="margin-left:5px"></b-icon> </b-button>
      </div>
    </b-card>
  </div>
</template>

    
    
<script>
import {eventBus} from "@/main";
// import VueZoomer from 'vue-zoomer'
// import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import InnerImageZoom from 'vue-inner-image-zoom'
// Todo: remove all these unused imports if nobody requests the zoom to come back
import vuePhotoZoomPro from 'vue-photo-zoom-pro'
import 'vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css'

export default {
  name: "Product",
  data(){
    return {
    }
  },
  components:{
    // VZoomer: VueZoomer.Zoomer,
    // 'inner-image-zoom': InnerImageZoom,
    // vuePhotoZoomPro
    },
  props: {
    product: Object,
  },

  created() {      
    eventBus.$on('refresh-product',  (payload) => {
      if (payload.id === this.product.id) {
          this.update();
      }
    })
  },

methods: {
    update() {
       this.$forceUpdate()
    },
    sendData(product){
      product.qty = Number(this.qty)
      this.$emit('add-cart', product)
    },

},      
}

</script>

<style scoped>



.card {
  width: 15.5rem;
  border: none;
}
.title {
  min-height: 3.5rem;
  font-size: 18px;
  font-weight: bold;
}
.button-group {
  text-align: center;
  display: flex;
}
.btn-add {
  background-color: rgb(0, 56, 101);
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 120%;
  height: 3rem;
}
.btn-rem {
  background-color: #767676;
  color: white;
  font-size: 14px;
  font-weight: bold;
  width: 120%;
  height: 3rem;
}
.img {
    text-align: center;
    margin-bottom: 1rem;
    overflow: hidden;
    width: 100%;
    height: 100%;
    object-fit: fill
}
.img img {
  transition: transform .3s ease;
}


.wheel-up{
  margin-right: 10px;
  height: 3rem;
  color: black;
}

</style>

