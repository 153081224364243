import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home.vue'
import Index from '@/views/Index.vue'
import LocalStorageService from "@/common/LocalStorageService";

const localStorageService = LocalStorageService.getService();

Vue.use(Router)

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'index',
      meta: {
        requiresAuth: false
      },
      component: Home
    },
    {
      path: '/home',
      name: 'home',
      meta: {
        requiresAuth: false
      },
      component: Home
    },
    {
      path: '/products/:storeId',
      name: 'products',
      meta: {
        requiresAuth: false
      },
      component: Index
    },
    {
      path: '/admin',
      name: 'admin-stores',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "admin-stores" */ './views/admin/Index.vue')
    },
    {
      path: '/admin/store/:storeId',
      name: 'admin-store',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "admin-store" */ './views/admin/ManageStore.vue')
    },
    {
      path: '/admin/catalog/:storeId',
      name: 'admin-catalog',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "admin-catalog" */ './views/admin/ManageCatalog.vue')
    },
    {
      path: '/admin/product/:storeId/:categoryId/:productId',
      name: 'admin-product',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "admin-product" */ './views/admin/ManageProduct.vue')
    },
    {
      path: '/admin/category/:storeId/:categoryId/:numProducts',
      name: 'admin-category',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "admin-category" */ './views/admin/ManageCategory.vue')
    },
    {
      path: '/admin/orders/:storeId',
      name: 'admin-orders',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "admin-orders" */ './views/admin/ManageOrders.vue')
    },
    {
      path: '/admin/order/:storeId/:orderId',
      name: 'admin-order',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "admin-order" */ './views/admin/ManageOrder.vue')
    },
    {
      path: '/admin/inventory/:storeId',
      name: 'admin-inventory',
      meta: {
        requiresAuth: true
      },
      component: () => import(/* webpackChunkName: "admin-order" */ './views/admin/ManageInventory.vue')
    },
    {
      path: '/timeout',
      name: 'timeout',
      meta: {
        requiresAuth: false
      },
      component: () => import(/* webpackChunkName: "timeout" */ './views/Timeout.vue')
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      meta: {
        requiresAuth: false
      },
      component: () => import(/* webpackChunkName: "unauthorized" */ './views/Unauthorized.vue')
    },

    {
      path: '*', 
      component: Home
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (router.app.$keycloak && router.app.$keycloak.authenticated) {
      if (to.meta.authRoles) {
        let hasRole = false
        for (var i = 0; i < to.meta.authRoles.length; i++) {
          if (router.app.$keycloak.hasResourceRole(to.meta.authRoles[i])) {
            hasRole = true;
          }
        }
        if (hasRole) {
          next()
        } else {
          router.push('unauthorized') 
        }
      } else {
        next();
      }
    } else {
      const url = localStorageService.getLoginUrl()
      window.location.replace(url)
    }
  } else {
    next()
  }
})

let announcer = document.getElementById('announcer');
router.afterEach(function(to) {
  if (!to.name) { return; }
  announcer.innerHTML = `${to.name} page has loaded`;
});

export default router