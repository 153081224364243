<template>
<main>
    <h3 class="category-name"> {{ category.name }} </h3>
    <img v-if="storeId === 'nbs' && getCatIcon != null"
         class="category-img" :src="getCatIcon" :alt="getAltText"
    />
    <div class="product justify-content-center">
        <Product  @rem-cart="$emit('rem-cart', product.id)" @add-cart="$emit('add-cart', product)" :key="product.id" v-for="product in category.products" :product="product"/>
    </div>
</main>


</template>

<script>
import Product from './Product.vue'

export default {
    name: 'Category',
    props: {
        category: Object,
        storeId: String,
    },
    components: {
        Product
    },
    emits: ['add-cart','rem-cart'],
    computed: {
      getCatIcon(){
        switch (this.category.id){
          case ('52a430b7-a985-4d3b-bfd3-7f89d46685c3'):
            //Newborn Screening Hearing
            return require('@/assets/Hearing Icon.png');
          case ('49bd45ce-b632-43af-99b9-2a175f9e8816'):
            //Newborn Pulse Oximetry Screening
            return require('@/assets/Pulse Ox Icon.png');
          default:
            return require('@/assets/3icons - no circles merged.2021-01.png');
        }
      },
      getAltText(){
        switch (this.category.id){
          case ('52a430b7-a985-4d3b-bfd3-7f89d46685c3'):
            //Newborn Screening Hearing
            return "NBS Hearing Icon";
          case ('49bd45ce-b632-43af-99b9-2a175f9e8816'):
            //Newborn Pulse Oximetry Screening
            return "NBS Pulse Oximetry Icon";
          default:
            return "Three Circle NBS Icon";
        }
      },
    },
}
</script>

<style scoped>
.category-name {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    margin: 1rem;
    color: rgb(0, 56, 101); 
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 3px solid rgb(0, 56, 101);

}
.category-img{
  max-height: 80px;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.product {
    display: flex;
    flex-wrap: wrap;
}

/* Tablet */
@media (max-width: 500px) {
    .product {
        display: flex;
        flex-wrap: wrap;
    }
}

/* Mobile */
@media (max-width: 500px) {
    .product {
        display: flex;
        flex-wrap: wrap;
    }
    .category-name {
        text-align: center;
        font-size: 18px;
    }
}

</style>
